import { useState, useEffect } from 'react'
import axios from "axios";
import { Link } from "react-router-dom";
import Navbar from "./NavBar";
 
const ProductList = () => {
    const [products, setProduct] = useState([]);
 

 
    const getProducts = async () => {
        const response = await axios.get('/products');
        setProduct(response.data);
    }

     useEffect(() => {
        getProducts();
    }, []);
 
    const deleteProduct = async (id) => {
        await axios.delete(`/products/${id}`);
        getProducts();
    }
 
    return (
        <div>
            <Navbar/>
            {/* <Link to="/add" className="button is-primary ">Add New</Link> */}
            <table className="table">
                <thead class="table-secondary">
                    <tr>
                        <th>No</th>
                        <th>Id</th>
                        <th>Nombre</th>
                        <th>Acciones</th>
                   </tr>
                </thead>
                <tbody>
                    { products.map((product, index) => (
                        <tr key={ product.id }>
                            <td>{ index + 1 }</td>
                            <td>{product.id}</td>
                            <td>
                            <Link to={ `/${product.id}`} style={{ textDecoration: 'none' }} > 
                                { product.title }
                            </Link>
                            </td>
                            <td>
                                <Link to={`/edit/${product.id}`} style={{ textDecoration: 'none' }} className="button is-small is-danger">Edit </Link>
                                <button onClick={ () => deleteProduct(product.id) } type="button" class="btn btn-danger">Delete</button>
                            </td>
                        </tr>
                    )) }
                     
                </tbody>
            </table>
        </div>
    )
}
 
export default ProductList
import { useState } from 'react'
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Navbar from "./NavBar";
 
const AddProduct = () => {
    const [title, setTitle] = useState('');
    const navigate = useNavigate();
 
    const saveProduct = async (e) => {
        e.preventDefault();
        await axios.post('/products',{
            title: title,
       
        });
        navigate("/");
    }
 
    return (
        <div>
            <Navbar/>
        <div className="container">
            <h2>Agregar Producto</h2>
     

            <form>
                <div class="mb-3">
                    <label for="title" class="form-label">Nombre</label>
                    <input 
                        style= {{width: "500px"}}
                        type="text" 
                        class="form-control" 
                        id="title" 
                        placeholder="Ingrese el nombre"
                        value={ title }
                        onChange={ (e) => setTitle(e.target.value) }
                    />
                </div>

                <div className="field">
                    <button onClick={ saveProduct } type="button" class="btn btn-primary">Guardar</button>
                </div>
            </form>
        </div>
        </div>
    )
}
 
export default AddProduct
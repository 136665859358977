import { useState, useEffect } from 'react'
import axios from "axios";
import { useNavigate, useParams } from 'react-router-dom';
import NavBar from "./NavBar";
 
const EditProduct = () => {
    const [title, setTitle] = useState('');
    const navigate = useNavigate();
    const { id } = useParams();
 
    const updateProduct = async (e) => {
        e.preventDefault();
        await axios.patch(`/products/${id}`,{
            title: title,
  
        });
        navigate("/");
    }
 

 
    const getProductById = async () => {
        const response = await axios.get(`/products/${id}`);
        setTitle(response.data.title);
      }
    useEffect(() => {
        getProductById();
    }, );

 
    return (
        <div>
            <NavBar/>
            <h2>Editar Producto</h2>
            <form >
            <div class="mb-3">
                    <label for="title" class="form-label">Nombre</label>
                    <input 
                        style= {{width: "500px"}}
                        type="text" 
                        class="form-control" 
                        id="title" 
                        placeholder="Ingrese el nombre"
                        value={ title }
                        onChange={ (e) => setTitle(e.target.value) }
                    />
                </div>



              <div className="field">
                    <button onClick={ updateProduct } className="button is-primary">Update</button>
                </div>
            </form>
        </div>
    )
}
 
export default EditProduct
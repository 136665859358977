import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProductList from "./components/ProductList";
import AddProduct from "./components/AddProducts";
import EditProduct from "./components/EditProducts";
import ProductDetail from "./components/ProductDetail"
 
function App() {
  return (
    <Router>
    <div className="container">
      <div className="columns">
        <div className="column is-half is-offset-one-quarter">
          <Routes>
            <Route exact path="/" element={<ProductList />}/>

            <Route exact path="/:id" element={<ProductDetail />}/>
              
            <Route exact path="/add" element={<AddProduct />}/>
   
            <Route exact path="/edit/:id" element={<EditProduct />}/>
            
          </Routes>
        </div>
      </div>
    </div>
    </Router>
  );
}
 
export default App;

import { useState, useEffect } from 'react'
import {useParams} from 'react-router-dom'
import axios from "axios";
import Navbar from "./NavBar";
// import { Link } from "react-router-dom";

const ProductDetail = () => {
    const {id} = useParams();
    const [products, setProduct] = useState([]);
    const [etiquetas, setEtiquetas] = useState([]);
    const [nombre, setNombre] = useState([]);
    console.log(id)

    const getProductsId = async () => {
        const response = await axios.get(`/products/${id}`);
        setProduct(response.data);
    }

    const getEtiquetas = async () => {
        const response = await axios.get(`/products/etiqueta/${id}`);
        setEtiquetas(response.data);
    }
    
    const deleteEtiqueta = async (id) => {
        await axios.delete(`/products/etiqueta/${id}`);
        getEtiquetas();
    }
    console.log(nombre)
    const addEtiqueta= async (e) => {
        e.preventDefault();
        await axios.post('/products/etiqueta/',{
            id_producto: products.id,
            nombre: nombre
       
        });
        //alert("Etiqueta agregada: " + nombre + products.id)
        //window.location.reload(); 
        getEtiquetas();
    }

    useEffect(() => {
        getProductsId(products);
        getEtiquetas(etiquetas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    } , []);



    return (
        <>
        <Navbar/>
        <table className="table">
                <thead class="table-secondary">
                    <h2>Producto: { products.title } </h2>
                    <tr>
                        <th>No</th>
                        <th>Id Etiqueta</th>
                        <th>Id Producto</th>
                        <th>Nombre</th>
                        <th>Acciones</th>
                   </tr>
                </thead>
                <tbody>
                { etiquetas.map((etiqueta, index) => (
                        <tr key={ etiqueta.id }>
                            <td>{ index + 1 }</td>
                            <td>{ etiqueta.id }</td>
                            <td>{ etiqueta.id_producto }</td>
                            <td>{ etiqueta.nombre }</td>
                           
                            <td>
                                {/* <Link to={`/edit/${etiqueta.id}`} style={{ textDecoration: 'none' }} className="button is-small is-danger">Edit </Link> */}
                                <button onClick={ () => deleteEtiqueta(etiqueta.id) } type="button" class="btn btn-danger">Delete</button>
                            </td>
                        </tr>
                    )) }
                </tbody>

                <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Add Etiqueta
                </button>
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Agregar Etiqueta</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                    <form onSubmit={ addEtiqueta }>
                <div className="field">
                    <label className="label">Nombre:  </label>
                    <input 
                        className="input"
                        type="text"
                        placeholder="nombre"
                        value={ nombre }
                        onChange={ (e) => setNombre(e.target.value) }
                    />
                </div>
                </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                        <button onClick = {addEtiqueta} type="button" class="btn btn-primary" data-bs-dismiss="modal">Guardar</button>
                    </div>
                    </div>
                </div>
                </div>

            </table>
        </>
    )
}

export default ProductDetail